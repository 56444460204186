<template>
  <div class="demand-proxy">
    <panel-title icon="el-icon-s-opportunity" title="代发需求" />

    <panel-content>
      <div slot="filter">
        <span>时间：</span>
        <el-date-picker
          style="margin: 0 10px; width: 250px"
          size="medium"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <span>项目名称：</span>
        <el-input
          size="medium"
          style="width: 200px; margin: 0 10px"
          placeholder="请输入需求名称"
        />
        <el-button type="primary" size="medium">立即查询</el-button>
        <el-button type="primary" size="medium" icon="el-icon-s-promotion"
          >软件代发</el-button
        >
        <el-button type="primary" size="medium" icon="el-icon-s-promotion"
          >硬件代发</el-button
        >
      </div>

      <div slot="content" class="table-wrap">
        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <el-table-column label="代发人"> </el-table-column>

          <el-table-column prop="name" label="项目名称" width="120">
          </el-table-column>

          <el-table-column prop="name" label="发布状态" width="120">
          </el-table-column>

          <el-table-column prop="name" label="发布时间" width="120">
          </el-table-column>

          <el-table-column prop="name" label="是否本人代发" width="120">
          </el-table-column>

          <el-table-column prop="name" label="操作" width="120">
          </el-table-column>
        </el-table>
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },
};
</script>

<style lang="scss" scoped>
.demand-proxy {
  .table-wrap {
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }
}
</style>